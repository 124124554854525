//import * as React from 'react';
import React, { useState } from 'react';
import { useSelector } from 'store';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import NavItem from './NavItem';
import NavDropDownItem from './NavDropDownItem';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

import { USER_ROLES } from 'constants/index';

// @ts-ignore
const computeNavItems = (t: ReturnType<typeof useTranslation>['t'],language: string, user: User) => {

  var userGuideLink = t('dashboard:generalInfo.userGuideLinkProjectLead');  
  if (user && user?.role?.type === USER_ROLES['Vendor(Serviceprovider)']) {
    userGuideLink = t('dashboard:generalInfo.userGuideLinkServiceProvider');
  }
  
  return [
    { text: t('navigation.about'), pathname: '/#about', ind: 1 },
    { text: t('navigation.forEducators'), pathname: '/#howitworks', ind: 2  },
    { text: t('navigation.forProfessionals'), pathname: '/#vendors', ind: 3  },
    { text: t('navigation.impact'), pathname: '/#impact', ind: 4  },
    {
      text: t('navigation.Others'),
      pathname: '/',
      dropdown: true,
      dropdownProps: [
        {
          text: t('navigation.pricing'), //Pricing 
          path: t('dashboard:generalInfo.pricingLink'),   
          target: '_blank',
          ind: 6
        },
        {
          text: t('navigation.GetStarted'), //UserGuide
          path: userGuideLink,  
          target: '_blank',
          ind: 7 
        },
        {
          text: t('navigation.FAQ'), //FAQ
          path: t('dashboard:generalInfo.faqLinkProjectLead'),   
          target: '_blank',
          ind: 8 
        },
        {
          text: t('navigation.oexDocuments'), //OEX Documents
          path: t('dashboard:generalInfo.resourcesLinkProjectLead'),   
          target: '_blank',
          ind: 9 
        },
      ],
      ind: 10 
    },
    {
      text: t('navigation.ContactUs'),
      pathname: 'mailto:exchange@ecampusontario.ca',
      ind: 1 
    },
  ];
};

const MainNavbar = () => {
  const { t } = useTranslation(['common', 'dashboard']);  
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  const language = localStorage.getItem('i18nextLng')||i18n.language;
  const navItems = React.useMemo(() => {
    return computeNavItems(t,language, user);
  }, [t]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        marginX: 1,
      }}
    >
      {navItems.map((item) => (
        <React.Fragment>
          {item.dropdown ? (
            <NavDropDownItem
              dropdownProps={item.dropdownProps}
              key={item.ind}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavDropDownItem>
          ) : (
            <NavItem
              key={item.ind}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavItem>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};
export default MainNavbar;
