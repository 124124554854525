import React from 'react';
import { Link, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type DropdownProps = {
  text: string;
  path: string;
  target: string;
};

type Props = {
  children: React.ReactNode;
  dropdownProps: DropdownProps[];
  pathname: string;
  active?: boolean;
  activeColor?: string;
};

const NavDropDownItem: React.FC<Props> = ({
  children,
  pathname,
  active = false,
  dropdownProps,
  activeColor,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        padding: (theme) => theme.spacing(1),
        marginRight: (theme) => theme.spacing(3),
        marginLeft: (theme) => theme.spacing(3),
        '@media screen and (max-width: 1299px)': {
          display: 'none',
        },
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ textTransform: 'none' }}
      >
        {children} <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {dropdownProps.map((i) => (
          <MenuItem key={i.text} onClick={handleClose}>
            <Link
              style={{ textDecoration: 'none' }}
              href={i.path}
              onClick={handleClose}
              target={i.target}
            >
              {i.text}
            </Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleClose}></MenuItem>
      </Menu>
    </Box>
  );
};

export default NavDropDownItem;
